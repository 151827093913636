import { TooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { FullPageSpinner } from '../components';
import { FullScreenProvider } from '../context';

import { StoreProvider } from './StoreProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 5 * 2,
      retry: 1,
      refetchOnWindowFocus: true,
      refetchOnMount: true,

      // Exponential backoff with jitter
      retryDelay(failureCount) {
        const maxDelay = 30000;
        const baseDelay = Math.min(1000 * 2 ** failureCount, maxDelay);
        const jitter = Math.random() * (maxDelay - baseDelay);

        return baseDelay + jitter;
      },
    },
  },
});

const AppProviders = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<FullPageSpinner />}>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <TooltipProvider delayDuration={50} skipDelayDuration={50}>
            <FullScreenProvider>{children}</FullScreenProvider>
            {/*<ReactQueryDevtools buttonPosition="bottom-right" />*/}
          </TooltipProvider>
        </StoreProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </Suspense>
);

export default AppProviders;
